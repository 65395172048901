.c-footer{
    background: $colour-2;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: $inuit-global-spacing-unit;
    //border-top: 1px solid white;

    .c-footer__contact {
        order: 3;
    }

    .c-social {
        justify-content: center;
        margin-bottom: $inuit-global-spacing-unit;
        li {
            margin: 0 $inuit-global-spacing-unit-tiny 0 0;
        }
        svg {
            fill: white;
        }
        a {
            &:hover, &:focus {
                svg {
                    fill: $colour-1;
                }
            }
        }
    }

    a {
        color: white;
    }

    .c-footer__logo {
        order: 1;
        margin-bottom: $inuit-global-spacing-unit-small;
        svg {
            width: 240px;
            height: auto;
            .site-logo-a {
                transition: fill 0.3s ease;
                fill: $colour-1;
            }
            .site-logo-b {
                fill: white;
            }
        }
        &:hover, &:focus {
            svg {
                .site-logo-a {
                    fill: $colour-3;
                }
            }
        }
    }

    address {
        margin-bottom: 0;
        font-style: normal;
        text-align: center;
        font-size: 0.8em;
    }

    .c-footer__nav {
        order: 2;
        font-size: 0.8em;
        margin-bottom: $inuit-global-spacing-unit;
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
            li {
                margin: 0 $inuit-global-spacing-unit-small;
                a {
                    color: white;
                    &:hover, &:focus {
                        color: $colour-1;
                    }
                }
            }
        }
    }

    @include media-query(medium-and-up) {
        padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
    }

    @include media-query(large) {
        flex-direction: row;
        .c-footer__contact {
            order: 1;
            .c-social {
                justify-content: flex-start;
                margin-bottom: $inuit-global-spacing-unit-small;
            }
            address {
                text-align: left;
            }
        }
        .c-footer__logo {
            order: 2;
            margin: 0;
        }
        .c-footer__nav {
            order: 3;
            margin-bottom: 0;
            ul {
                li {
                    margin: 0 0 0 $inuit-global-spacing-unit;
                }
            }
        }
    }

}
