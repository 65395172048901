.c-media-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $inuit-global-spacing-unit-large;

    figcaption {
        font-size: 0.9em;
        margin: 0.25em 0;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    .c-media-text__media {
        width: 100%;
        margin: 0 0 $inuit-global-spacing-unit 0;
    }
    .c-media-text__text {
        width: 100%;
        margin: 0;
    }

    @include media-query(large) {
        flex-direction: row;

        &.c-media-text--media-right {
            .c-media-text__media {
                order: 2;
            }
            .c-media-text__text {
                order: 1;
            }
        }

        .c-media-text__media {
            width: calc(50% - 24px);
            margin: 0;
        }
        .c-media-text__text {
            width: calc(50% - 24px);
        }
    }

}
