/* ==========================================================================
   #IFRAMES
   ========================================================================== */

/**
 * 1. Resize video iframe to figure ratio.
 */

.iframe-wrapper {

  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: calc(100% / (16/9));

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

}
