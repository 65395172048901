.c-accordion {
    margin: 0;
    padding: 0;

    dt {
        margin: 0;
    }

    dd {
        padding: 0 0.5em;
        margin: 0 0 $space 0;
    }

    + p {
        margin-top: $space-large;
    }

    .c-accordion__button {
        background: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.25rem 1.5rem 0.25rem 0.5rem;
        border: 0;
        border-bottom: solid 1px $colour-1;
        color: $colour-2;
        text-align: left;
        cursor: pointer;
        position: relative;

        svg {
            position: absolute;
            transition: transform 0.3s ease;
            pointer-events: none;
            fill: $colour-1;
            width: auto;
            height: 0.5rem;
            right: 0.5rem;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover, &:focus {
            background: $colour-1;
            svg {
                fill: white;
            }
        }

        &.is-open {
            background: $colour-1;
            svg {
                transform: translateY(-50%) rotate(-180deg);
                fill: white;
            }
        }
    }

    .c-accordion__panel {
        height: 0;
        opacity: 0;
        transition: opacity 0.3s ease, height 0.3s ease;
        overflow: hidden;

        p:first-of-type {
            margin-top: 0.5rem;
        }

        &.is-visible {
            opacity: 1;
            height: auto;
            max-height: 1000px;
            background: lighten($colour-4, 5%);
        }
    }
}
