.c-newsletter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;

    .c-newsletter__fields {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 1em;
        flex: 0 0 auto;

        input {
            width: 100%;
        }
    }

    .c-newsletter__privacy {
        font-size: 0.9em;

        a {
            color: $colour-1;

            &:hover, &:focus {
                color: white;
            }
        }
    }

    button {
        margin-top: 1.45em;
        padding: 0;
        height: 52px;
    }

    @include media-query(medium-and-up) {
        flex-direction: row;
        .c-newsletter__fields {
            width: calc(50% - 12px);
        }
    }

    @include media-query(large) {
        .c-newsletter__fields {
            width: calc(25% - 18px);
        }
    }
}
