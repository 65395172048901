.c-media {
    figure {
        margin: 0;
        figcaption {
            font-size: 0.9em;
            margin: 0.25em 0;
        }
    }
    margin-bottom: $inuit-global-spacing-unit-large;

    &.o-wrapper--full {
        figcaption {
            padding: 0.25em 0 0.25em $inuit-global-spacing-unit;
            max-width: $global-inner-limit;
        }
    }

    @include media-query(xlarge){
        &.o-wrapper--full {
            figcaption {
                margin: 0 auto;
            }
        }
    }
}
