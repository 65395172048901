.c-alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparentize($colour-2, 0.2);
    transition: opacity 0.3s ease;
    transition-delay: 0.3s;

    &.is-visible {
        display: flex;
    }

    .c-alert__window {
        position: relative;
        background: white;
        min-width: calc(100% - 48px);
        padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit $inuit-global-spacing-unit;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
        transition: opacity 0.3s ease;
    }

    &.fade-out {
        opacity: 0;
        .c-alert__window {
            opacity: 0;
        }
    }

    h2 {
        text-align: center;
    }

    .c-alert__close {
        float: right;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        padding: $inuit-global-spacing-unit-small;
        svg {
            height: $inuit-global-spacing-unit;
            width: auto;
            margin-left: 0.5em;
            fill: $colour-2;
            transition: fill 0.3s ease;
        }
        &:hover, &:focus {
            svg {
                fill: white;
            }
        }
    }

    .c-alert__link {
        display: block;
    }

    @include media-query(medium-and-up) {
        .c-alert__window {
            min-width: 640px;
        }
    }

}
