.c-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $inuit-global-spacing-unit-large 0 $inuit-global-spacing-unit;
    ul {
        display: none;
        align-items: center;
        justify-content: center;
        margin: 0;
        .c-btn {
            min-width: 56px;
        }
    }
    .c-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 0.25em;
        background: white;
        &.is-disabled {
            opacity: 0.5;
            pointer-events: none;
        }
        svg {
            height: 1em;
            width: auto;
            fill: $colour-2;
            transition: $global-transition;
        }
        &:hover, &:focus, &.is-current {
            background: $colour-1;
            color: $colour-2;
            svg {
                fill: $colour-2;
            }
        }
    }

    .c-pagination__prev {
        svg {
            margin-right: 1em;
        }
        &:hover, &:focus {
            svg {
                transform: translateX(-4px);
            }
        }
    }

    .c-pagination__next {
        svg {
            margin-left: 1em;
        }
        &:hover, &:focus {
            svg {
                transform: translateX(4px);
            }
        }
    }

    @include media-query(medium-and-up) {
        ul {
            display: flex;
        }
    }
}

.c-post-list--highlight {
    background: $colour-1;
    .c-pagination {
        .c-btn {
            &:hover, &:focus, &.is-current {
                background: $colour-3;
            }
        }
    }
}
