/* ==========================================================================
 #WRAPPER
 ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

$inuit-wrapper-width: 800px !default;
$inuit-wrapper-width-wide: 1248px !default;

@if (type-of($inuit-wrapper-width) != number) {
    @error "`#{$inuit-wrapper-width}` needs to be a number.";
}

.o-wrapper {
    width: 100%;
    padding-right: $inuit-global-spacing-unit;
    padding-left: $inuit-global-spacing-unit;
    margin-right: auto;
    margin-left: auto;
    max-width: $inuit-wrapper-width;
}

.o-wrapper--wide {
    max-width: $inuit-wrapper-width-wide;
}

.o-wrapper--full {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
}
