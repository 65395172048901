label {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 6px;
  span {
    color: $colour-3;
  }

  .label__flag {
    margin-left: 0.5em;
    font-size: 0.75em;
    color: $colour-2;
  }
}

input[type="text"], input[type="email"], textarea {
  display: block;
  font-family: $global-font-family;
  width: 100%;
  background: white;
  padding: 12px; // Button height
  line-height: $space;
  border: 2px solid white;
  border-radius: 0;
  &:focus {
    box-shadow: none;
  }
}

.is-valid {
  input[type="text"], input[type="email"], textarea, select {
    border: 2px solid $colour-valid;
  }
  .js-field-feedback {
    display: none;
  }
}

.is-invalid {
  input[type="text"], input[type="email"], textarea, select {
    border: 2px solid $colour-invalid;
  }
}

.js-field-feedback {
  font-weight: bold;
}

.grecaptcha-badge {
  z-index: 1000;
}

.field--highlight {
  background: $colour-2;
  padding: $space;
  label {
    color: white;
  }
  .js-field-feedback {
    color: white;
  }
}

select {
  font-family: $global-font-family;
  display: block;
  padding: ($space-small - 2px) $space-large ($space-small - 2px)  $space-small;
  line-height: $space;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  vertical-align: middle;
  margin: 0;
  border: solid 2px white;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('../img/icon-caret-down.svg');
  background-repeat: no-repeat;
  background-position: right .7em top 50%;
  background-size: .65em auto;
  height: $space-large;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    color: #222;
  }

  option {
    font-weight: normal;
  }
}

button, input, select {
  font-family: $global-font-family;
}

.input-checkbox {
  display: inline-block;
  position: relative;
  margin-bottom: 1rem;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.check__control {
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:focus {
    ~ .check__body {
      &::before {
        box-shadow: 0 0 0 3px rgba($colour-2, .5);
        border: 2px solid $colour-2;
      }
    }
  }

  &:checked {
    ~ .check__body {
      &::before {
        background-color: $colour-2;
        border: 2px solid $colour-2;
      }

      &::after {
        color: white;
      }
    }
  }
}

.check__body {
  display: flex;

  &::before,
  &::after {
    content: "";
    flex-shrink: 0;
  }

  &::before {
    top: 0;
    left: 0;
    background: white;
    width: $space;
    height: $space;
    margin-right: $space-small;
    border: 2px solid $colour-2;
    transition: background-color 0.3s, box-shadow 0.3s;
  }

  &::after {
    position: absolute;
    top: 12px;
    left: 12px;
    transform: translate(-50%, -50%) translateY(-2px) rotate(45deg);
    width: 8px;
    height: 16px;
    color: transparent;
    border-right: 2px solid;
    border-bottom: 2px solid;
    transition: color 0.3s;
  }
}

.check__text {
  font-size: 1rem;
  align-self: center;
  font-weight: 400;
  color: $black;

  a {
    text-decoration: underline;
    font-weight: 600;
    color: $colour-2;

    &:hover {
      color: $colour-1;
    }
  }
}

#competition, #contact {
  border: solid 1px white;
  padding: $space;
}

#competition {
  margin-top: $space;
}


