.c-icon-list {

    ul {
        display: flex;
        margin-bottom: 0;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -12px;
    }

    li {
        width: calc(100% - 24px);
        margin: 0 12px 24px 12px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
    }

    figure {
        width: 100%;
        height: 0;
        padding-top: 50%;
        position: relative;
        margin-bottom: 0;
        overflow: hidden;
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 70%;
            height: 70%;
            object-fit: contain;
            font-family: 'object-fit: contain;';
            fill: $colour-2;
            transform: translate(-50%, -50%);
        }
    }

    h3 {
        text-align: center;
        margin: 0;
        padding: 0 $space-small $space-small;
    }

    @include media-query(medium-and-up) {
        .c-icon-list {
            &__items-4 {
                li {
                    width: calc(50% - 24px);
                }
            }
            &__items-5 {
                li {
                    width: calc(33.333% - 24px);
                    &:nth-of-type(1), &:nth-of-type(2) {
                        width: calc(50% - 24px);
                    }
                }
            }
            &__items-6 {
                li {
                    width: calc(33.333% - 24px);
                }
            }
        }
    }

    @include media-query(large) {
        .c-icon-list {
            &__items-2 {
                li {
                    width: calc(50% - 24px);
                }
            }
            &__items-3 {
                li {
                    width: calc(33.333% - 24px);
                }
            }
            &__items-4 {
                li {
                    width: calc(25% - 24px);
                }
            }
        }
    }
}
