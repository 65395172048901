.o-content {

    color:$black;

    h1, h2, h3, h4, h5, h6 {
        color: $colour-2;
    }

    .o-content__header {
        text-align: center;
        background: $colour-2;
        padding: $inuit-global-spacing-unit;
        position: relative;
        h1, p {
            color: white;
            max-width: $inuit-wrapper-width;
        }
        h1 {
            margin: 0 auto;
        }
        p {
            margin: $inuit-global-spacing-unit-small auto 0 auto;
            a {
                color: white;
                &:focus, &:hover {
                    color: $colour-1;
                }
            }
        }
    }
    .o-content__back {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        margin-top: 1em;
        svg {
            height: 1em;
            fill: $colour-1;
            margin-right: 0.5em;
            transition: all 0.3s ease;
        }
        &:hover, &:focus {
            color: $colour-1;
            svg {
                transform: translateX(-0.25em);
            }
        }
    }
    section:first-of-type {
        &.o-content__margin {
            margin-top: $inuit-global-spacing-unit-large
        }
    }

    @include media-query(medium-and-up) {
        .o-content__header {
            padding: $inuit-global-spacing-unit-large;
        }
        .o-content__back {
            margin: 0;
            position: absolute;
            bottom: $inuit-global-spacing-unit-large;
        }
    }
}

.c-feature + .o-content__margin,
.c-feature + .o-wrapper--normal,
.c-feature + .o-wrapper--wide {
    margin-top: $inuit-global-spacing-unit-large
}

