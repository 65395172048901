/*!
 * Architecture here based on inuitcss, by @csswizardry
 * github.com/inuitcss | inuitcss.com
 */

// SETTINGS
@import "settings/example.settings.config";
@import "settings/settings.fonts";
@import "settings/settings.core";
@import "settings/settings.global";


// TOOLS
@import "tools/tools.font-size";
@import "tools/tools.clearfix";
@import "tools/tools.hidden";


// GENERIC
@import "generic/generic.box-sizing";
@import "generic/generic.normalize";
@import "generic/generic.reset";
@import "generic/generic.shared";
@import "generic/generic.media-queries";


// ELEMENTS
@import "elements/elements.page";
@import "elements/elements.headings";
@import "elements/elements.images";
@import "elements/elements.tables";
@import "elements/elements.forms";
@import "elements/elements.link";
@import "elements/elements.iframes";

// OBJECTS
//
// Uncomment and add to this section as necessary.
@import "objects/objects.wrapper";
@import "objects/objects.flex";
//@import "objects/objects.media";
//@import "objects/objects.flag";
@import "objects/objects.list-bare";
@import "objects/objects.list-inline";
@import "objects/objects.box";
@import "objects/objects.section";
//@import "objects/objects.block";
//@import "objects/objects.ratio";
//@import "objects/objects.crop";
@import "objects/objects.table";
//@import "objects/objects.pack";
@import "objects/objects.icons";
@import "objects/objects.content";

// COMPONENTS
//
// Build and include your project’s UI components here.
@import "components/components.buttons";
@import "components/components.header";
@import "components/components.footer";
@import "components/components.media";
@import "components/components.text";
@import "components/components.media-text";
@import "components/components.post-list";
@import "components/components.hero";
@import "components/components.feature";
@import "components/components.times";
@import "components/components.info-list";
@import "components/components.social";
@import "components/components.accordion";
@import "components/components.store-header";
@import "components/components.store-description";
@import "components/components.newsletter";
@import "components/components.alert";
@import "components/components.pagination";
@import "components/components.contact";
@import "components/components.flash";
@import "components/components.offer-code";
@import "components/components.cta";
@import "components/components.store-card";
@import "components/components.floor-plans";
@import "components/components.competition";
@import "components/components.newsletter-layout";
@import "components/components.icon-list";

// UTILITIES
@import "utilities/utilities.clearfix";

// Be aware that enabling offsets produces a large chunk of classes which might
// bloat your CSS, depending of the amount of breakpoints you defined.
// Only set this to `true` if you are absolutely sure about what you are doing.
$inuit-offsets: false;
@import "utilities/utilities.widths";
@import "utilities/utilities.headings";
@import "utilities/utilities.spacings";
@import "utilities/utilities.responsive-spacings";
@import "utilities/utilities.print";
@import "utilities/utilities.hide";
@import "utilities/utilities.colors";
