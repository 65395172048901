.c-post-list {
	background: $colour-4;
	padding: $inuit-global-spacing-unit;

	&.c-post-list--highlight {
		background: $colour-1;
		.c-post-list__update {
			&::before {
				background: $colour-1;
			}
		}
	}

	&.c-post-list--division {
		background: $colour-1;
		border-bottom: 1px solid darken($colour-2, 10%);
	}

	&:last-of-type {
		border-bottom: 0;
	}

	&.c-post-list--light {
		background: lighten($colour-4, 5%);
	}

	.c-post-list__update {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: -10px;
			left: -10px;
			width: calc(100% + 20px);
			height: calc(100% + 20px);
			background: $colour-4;
			z-index: 2;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.3s ease;
		}
		.o-icon-loop {
			display: none;
			position: absolute;
			top: 128px;
			left: calc(50% - 24px);
			width: $inuit-global-spacing-unit-large;
			height: auto;
			animation: rotating 2s linear infinite;
		}
		&.is-loading {
			&::before {
				opacity: 0.8;
				z-index: 2;
			}
			.o-icon-loop {
				display: block;
				z-index: 3;
			}
		}
	}

	header {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: $inuit-global-spacing-unit;
		column-gap: 1.25rem;
		row-gap: 0.5rem;
	}

	h1 {
		color: $colour-2;
		margin: 0;
	}

	h2 {
		color: $colour-2;
	}

	&__search {
		display: flex;
		flex-direction: column;
		margin-left: auto;
		width: 100%;
		max-width: 22rem;

		input {
			height: 48px;
		}
		p {
			color: $colour-2;
		}
		div {
			display: flex;
			align-items: center;
			justify-content: center;
			background: white;
		}
		svg {
			fill: $colour-2;
			height: 1rem;
			position: relative;
			top: 0.15rem;
		}
		button {
			border: none;
			background: none;
			padding: 0 0.5rem;
			cursor: pointer;
			&:hover {
				svg {
					fill: $colour-1;
				}
			}
		}
		.icon-search__close {
			display: none;
		}
	}

	&__search--main {
		margin-left: 0;
	}

	.c-post-list__inner {
		max-width: $global-inner-limit;
		margin: 0 auto;
	}

	.c-post-list__filter {
		margin-bottom: 0;
		width: 100%;

		label {
			display: block;
		}

		select {
			display: inline-block;
			width: 100%;
		}

		button {
			display: inline-block;
			margin-top: $inuit-global-spacing-unit-small;
		}
	}

	ul {
		margin-bottom: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		//align-items: flex-start;
		//justify-content: flex-start;
		margin: 0 -12px;
	}

	.c-post-list__card {
		width: calc(100% - 24px);
		margin: 0 12px 24px 12px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
	}

	.c-post-list__card--small {
		.c-post-list__link {
			background: $colour-2;
		}
		.c-post-list__text {
			h3,
			p {
				color: white;
			}
		}
		.c-post-list__image {
			padding-top: 33.333%;
		}
	}

	.c-post-list__image {
		width: 100%;
		height: 0;
		padding-top: 60%;
		position: relative;
		margin-bottom: 0;
		overflow: hidden;
		background: white;
		border-bottom: solid 1px $colour-4;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			width: calc(100% - 24px);
			height: calc(100% - 24px);
			object-fit: cover;
			font-family: 'object-fit: cover;';
			transform: translate(-50%, -50%);
		}
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 50%;
			height: 50%;
			object-fit: contain;
			font-family: 'object-fit: contain;';
			fill: $colour-1;
			transition: fill 0.3s ease;
			transform: translate(-50%, -50%);
		}
	}

	.c-post-list__image--logo {
		img {
			width: 64%;
			height: 64%;
			object-fit: contain;
			font-family: 'object-fit: contain;';
		}
		svg {
			width: 64%;
			height: 64%;
		}
		.site-logo-b {
			fill: $colour-2;
		}
	}

	.c-post-list__text {
		color: white;
		display: flex;
		flex-direction: column;
		transition: background-color 0.3s ease;
		padding: $inuit-global-spacing-unit-small;

		h3 {
			margin-bottom: 0;
			color: black;
		}

		p {
			color: $colour-2;
			margin-bottom: 0;
		}
	}

	.c-post-list__text-no-date {
		height: 100%;

		h3 {
			// margin-top: auto;
		}

		.mt-auto{
			margin-top: auto;
		}
	}

	.c-post-list__link {
		display: flex;
		height: 100%;
		flex: 1 1 auto;
		flex-direction: column;
		background: white;
		text-decoration: none;
		overflow: hidden;
		transition: box-shadow 0.3s ease, background-color 0.3s ease;
		box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);

		&:hover,
		&:focus {
			box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);

			.c-post-list__text {
				h3,
				p,
				span {
					color: white;
				}
			}
		}
	}

	li {
		&:nth-of-type(4n + 1) {
			.c-post-list__link {
				&:hover,
				&:focus {
					background: $colour-3;
					svg {
						fill: $colour-3;
					}
				}
			}
		}

		&:nth-of-type(4n + 2) {
			.c-post-list__link {
				&:hover,
				&:focus {
					background: $colour-5;
					svg {
						fill: $colour-5;
					}
				}
			}
		}

		&:nth-of-type(4n + 3) {
			.c-post-list__link {
				&:hover,
				&:focus {
					background: $colour-6;
					svg {
						fill: $colour-6;
					}
				}
			}
		}

		&:nth-of-type(4n + 4) {
			.c-post-list__link {
				&:hover,
				&:focus {
					background: $colour-7;
					svg {
						fill: $colour-7;
					}
				}
			}
		}
	}

	@include media-query(medium-and-up) {
		padding: $inuit-global-spacing-unit-large;
		.c-post-list__filter {
			width: auto;
		}
		.c-post-list__card {
			width: calc(50% - 24px);
		}
		header {
			flex-direction: row;
			align-items: flex-end;
		}

		h1 {
			margin: 0;
		}
	}

	@include media-query(large) {
		padding: $inuit-global-spacing-unit-large;
		.c-post-list__card {
			width: calc(33.333% - 24px);
		}
	}

	@include media-query(xlarge) {
		padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-huge;
	}
}

@keyframes rotating {
	0% {
		transform: rotate(360deg);
		fill: $colour-1;
	}
	50% {
		fill: $colour-3;
	}
	100% {
		transform: rotate(0deg);
		fill: $colour-1;
	}
}

.c-post-list__heading {
	margin-top: 2rem;
}

ul.c-post-list__news {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	padding: 12px;
	gap: 1.5rem;
	li {
		list-style: none;
		display: flex;
		gap: 1.5rem;
		position: relative;
	}
	figure {
		margin: 0;
		width: 40%;
		height: 0;
		padding-bottom: 40%;
		flex: 0 0 auto;
		position: relative;
		background: white;

		img {
			width: 100%;
			height: 100%;
			position: absolute;
			object-fit: cover;
		}

		svg {
			max-width: 80%;
			max-height: 80%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	h3 {
		word-break: break-word;
	}
	p {
		margin: 0;
	}
	a {
		color: $black;
		text-decoration: none;
		&:hover {
			color: $colour-1;
			text-decoration: underline;
		}
	}
	a::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	@include media-query(medium) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-query(large) {
		grid-template-columns: repeat(3, 1fr);
	}
}

ul.c-post-list__all {
	padding: 0 12px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	li {
		width: 100%;
		list-style: none;
	}
	p {
		margin: 0;
	}
}
