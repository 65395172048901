.c-feature {
	padding: $inuit-global-spacing-unit;
	color: $colour-2;
	position: relative;
	background: $colour-4;

	.c-feature__inner {
		max-width: $global-inner-limit;
		margin: 0 auto;
	}

	.c-feature__row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		h2 {
			width: 100%;
		}
	}

	.svg-place {
		z-index: 1;
		position: absolute;
		width: 116px;
		height: 60px;
		display: none;
		right: $inuit-global-spacing-unit;
		top: -$inuit-global-spacing-unit;
		mix-blend-mode: multiply;
	}

	ul,
	h2 {
		position: relative;
		z-index: 2;
	}

	&.c-feature--white {
		background: white;
	}

	&.c-feature--pattern {
		background: url('../img/uniform-pattern.svg');
		background-size: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-large;
		color: white;

		.c-feature__inner {
			background: $colour-2;
			padding: $inuit-global-spacing-unit;
		}
	}

	&.c-feature--pattern-alt {
		background: url('../img/circle-smile.svg');
		background-size: 192px 172px;
		color: white;

		.c-feature__inner {
			background: $colour-2;
			padding: $inuit-global-spacing-unit;
		}
	}

	&.c-feature--image {
		background-size: cover;
		background-position: center;
	}

	&.c-feature--light {
		background: lighten($colour-4, 5%);
	}

	&.c-feature--blue {
		background: $colour-1;
	}

	&.c-feature--store {
		background: $colour-1;
		padding: $space-large 0;
	}

	&:nth-of-type(3n + 1) {
		.svg-place {
			fill: $colour-1;
		}
	}

	&:nth-of-type(3n + 2) {
		.svg-place {
			fill: $colour-3;
		}
	}

	&:nth-of-type(3n + 3) {
		.svg-place {
			fill: $colour-6;
		}
	}

	.c-feature__times {
		width: 100%;
		position: relative;
		z-index: 2;

        .c-feature__times__link {
            margin-bottom: 1em;
        }
	}

	.c-feature__map {
		background: #bbb;
		flex: 1 1 auto;
		height: 360px;
		width: 100%;
		position: relative;
		color: white;
		z-index: 2;
		margin-bottom: $inuit-global-spacing-unit;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}
	}

	@include media-query(medium-and-up) {
		padding: $inuit-global-spacing-unit-large;
		.svg-place {
			display: block;
			width: 230px;
			height: 120px;
			top: -$inuit-global-spacing-unit-large;
			right: 15%;
		}

		&.c-feature--pattern {
			.c-feature__inner {
				padding: $inuit-global-spacing-unit-large;
			}
		}
		.c-feature__times {
			width: 50%;
			max-width: 350px;
		}
		.c-feature__map {
			width: auto;
			margin-left: $inuit-global-spacing-unit-large;
		}
	}

	@include media-query(large) {
		.svg-place {
			width: 270px;
			height: 140px;
			top: -$inuit-global-spacing-unit-large;
		}
		.c-feature__map {
			width: auto;
			height: 480px;
			margin-left: $inuit-global-spacing-unit-large;
			margin-bottom: $inuit-global-spacing-unit-large;
		}
	}

	@include media-query(xlarge) {
		padding: $inuit-global-spacing-unit-huge;
		.svg-place {
			width: 385px;
			height: 200px;
			top: -$inuit-global-spacing-unit-huge;
		}
		.c-feature__map {
			margin-left: $inuit-global-spacing-unit-huge;
		}
	}
}

@include media-query(large) {
	body:not(.home) {
		.c-feature {
			&__map {
				margin-bottom: 0;
			}
		}
	}
}
