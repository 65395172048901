.u-bg-0 {
    background: white;
}

.u-bg-1 {
    background: $colour-1;
}

.u-bg-2 {
    background: $colour-2;
}

.u-bg-3 {
    background: $colour-3;
}

.u-bg-4 {
    background: $colour-4;
}

.u-bg-4-glare {
    background: lighten($colour-4, 5%);
}

.u-bg-5 {
    background: $colour-5;
}

.u-bg-6 {
    background: $colour-6;
}

.u-bg-7 {
    background: $colour-7;
}
