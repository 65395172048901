.c-info-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: $black;

    dt {
        width: 8em;
        display: inline-flex;
        margin: 0 0 1em 0;
        font-weight: bold;
    }
    dd {
        width: calc(100% - 8em);
        display: inline-flex;
        margin: 0 0 0.5em 0;
        a {
            color: $colour-2;
            &:focus, &:hover {
                color: $colour-1;
            }
        }
    }
    address {
        font-style: normal;
        margin: 0;
    }
}
