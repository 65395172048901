.c-cta {
    display: flex;
    background: $colour-2;
    flex-direction: column;
    color: white;
    align-items: center;
    justify-content: center;
    position: relative;

    .svg-place {
        z-index: 1;
        position: absolute;
        width: auto;
        height: 120px;
        top: -30px;
        left: 10%;
        mix-blend-mode: multiply;
        fill: $colour-1;
    }

    figure {
        margin-bottom: 0;
        width: 100%;
        height: 0;
        padding-bottom: 60%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }

    .c-cta__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        padding: $space;

        a {
            &:hover, &:focus {
                color: white;
            }
        }
    }

    &.c-cta--right {
        background: $colour-4;
        .svg-place {
            left: auto;
            right: 10%;
            fill: $colour-3;
        }

        .c-cta__content {
            color: $colour-2;
        }
    }

    @include media-query(medium-and-up) {
        flex-direction: row;
        align-items: center;
        figure {
            display: flex;
            align-self: stretch;
            height: auto;
            width: 50%;
            padding-bottom: 0;
        }
        .c-cta__content {
            min-height: 500px;
            width: 50%;
            padding: $space-large $space-large $space-large $space;
        }
        &.c-cta--right {
            figure {
                order: 2;
            }
            .c-cta__content {
                order: 1;
                padding: $space-large $space $space-large $space-large;
            }
        }
        .svg-place {
            width: 30%;
            height: auto;
            top: -60px;
        }
    }

    @include media-query(xlarge) {
        .c-cta__content {
            padding: $space-huge $space-huge $space-huge $space-large;
        }
        &.c-cta--right {
            .c-cta__content {
                padding: $space-huge $space-large $space-huge $space-huge;
            }
        }
    }

}
