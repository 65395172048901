.c-text {
    p:last-of-type {
        margin-bottom: 0;
    }
    margin-bottom: $inuit-global-spacing-unit-large;

    img[width] {
        max-width: 100%;
        height: auto;
    }
}
