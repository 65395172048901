.c-store-main {
    background: white;
    .c-store-main__inner {
        display: flex;
        flex-direction: column;
        max-width: $global-inner-limit;
        margin: 0 auto;
    }

    article {
        width: 100%;
        max-width: 800px;
        margin: 0 0 $inuit-global-spacing-unit 0;
        h2, h3, h4 {
            color: $colour-2;
        }
    }

    .c-store-photos {
        width: 100%;
    }

    @include media-query(medium-and-up) {

        .c-store-main__inner {
            flex-direction: row;
            justify-content: space-between;
        }

        article {
            width: calc(50% - 24px);
        }

        .c-store-photos {
            width: calc(50% - 24px);
        }
    }

}
