/* red-hat-display-regular - latin */
@font-face {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    src: local('Red Hat Display'), local('RedHatDisplay-Regular'),
    url('../fonts/red-hat-display-v3-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/red-hat-display-v3-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* red-hat-display-italic - latin */
@font-face {
    font-family: 'Red Hat Display';
    font-style: italic;
    font-weight: 400;
    src: local('Red Hat Display It'), local('RedHatDisplay-Italic'),
    url('../fonts/red-hat-display-v3-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/red-hat-display-v3-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* red-hat-display-700 - latin */
@font-face {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    src: local('Red Hat Display Bold'), local('RedHatDisplay-Bold'),
    url('../fonts/red-hat-display-v3-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/red-hat-display-v3-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* red-hat-display-700italic - latin */
@font-face {
    font-family: 'Red Hat Display';
    font-style: italic;
    font-weight: 700;
    src: local('Red Hat Display Bold It'), local('RedHatDisplay-BoldItalic'),
    url('../fonts/red-hat-display-v3-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/red-hat-display-v3-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
