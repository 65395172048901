.o-inline-icon {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 12px;
    margin-right: 0.5em;
    height: 24px;
    svg {
        width: auto;
        height: 20px;
        fill: $colour-1;
    }
}
