.c-store-header {

    .c-store-header__inner {
        display: flex;
        flex-direction: column;
        max-width: $global-inner-limit;
        margin: 0 auto;
    }

    .c-store-header__info {
        width: 100%;

        .c-btn--1 {
            margin-bottom: $inuit-global-spacing-unit;
        }
    }

    .c-store-header__back {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1em;
        margin-bottom: 1em;
        color: $colour-2;
        svg {
            height: 1em;
            margin-right: 0.5em;
            fill: $colour-1;
            transition: all 0.3s ease;
        }
        &:focus, &:hover {
            text-decoration: underline;
            color: $colour-1;
            svg {
                fill: $colour-2;
                transform: translateX(-0.25em);
            }
        }
    }

    .c-store-header__logo {
        width: 100%;
        height: 0;
        padding-bottom: 60%;
        background: white;
        position: relative;
        img, svg {
            position: absolute;
            object-fit: contain;
            font-family: 'object-fit: contain;';
        }
        img {
            top: 10%;
            left: 10%;
            width: 80%;
            height: 80%;
        }
        svg {
            top: 25%;
            left: 25%;
            width: 50%;
            height: 50%;
            fill: $colour-1;
        }
    }

    h1 {
        color: $colour-2;
    }

    @include media-query(medium-and-up) {

        .c-store-header__inner {
            flex-direction: row;
            justify-content: space-between;
        }

        .c-store-header__info {
            width: calc(50% - 24px);
            .c-btn--1 {
                margin-bottom: 0;
            }
        }

        .c-times {
            max-width: 384px;
        }

        .c-store-header__logo {
            width: calc(50% - 24px);
            padding-bottom: calc(30% - 24px);
            margin-left: $inuit-global-spacing-unit;
        }
    }

}
