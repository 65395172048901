.c-hero {
    background: $colour-4;
    position: relative;
    margin-bottom: 0;
    background: $colour-2;

    .svg-place {
        fill: $colour-3;
        display: block;
        z-index: 2;
        position: absolute;
        top: 42vw;
        width: calc(100% - 48px);
        height: auto;
        mix-blend-mode: multiply;
        left: $inuit-global-spacing-unit;
    }

    .c-hero__image {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 80%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        z-index: 1;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    figcaption {
        margin: 0;
        //background: $colour-2;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: white;
        z-index: 3;
        position: relative;
        padding: $inuit-global-spacing-unit;
        &.c-hero__has-link {
            padding: $inuit-global-spacing-unit $inuit-global-spacing-unit $inuit-global-spacing-unit-large $inuit-global-spacing-unit;
        }
        h1 {
            color: white;
        }
    }
    .c-hero__scroll {
        display: none;
    }

    @include media-query(medium-and-up) {
        .c-hero__image {
            padding-top: calc(100% / (16/9));
        }
        .svg-place {
            top: 18vw;
        }
    }

    @include media-query(large) {
        height: calc(100vh - 96px);
        display: flex;
        flex-direction: column;
        align-items:  flex-end;
        justify-content: center;
        background: white;

        .c-hero__image {
            position: absolute;
            padding-top: 0;
            top: 0;
            left: 0;
            height: 100%;
        }

        figcaption {
            position: relative;
            background: transparentize($colour-2, 0.1);
            min-width: 320px;
            max-width: 500px;
            margin: 0 $inuit-global-spacing-unit-large 0 0;
            left: auto;
            right: auto;
            bottom: auto;
            padding: $inuit-global-spacing-unit;
            &.c-hero__has-link {
                padding: $inuit-global-spacing-unit $inuit-global-spacing-unit $inuit-global-spacing-unit-huge $inuit-global-spacing-unit;
            }
        }

        .svg-place {
            top: auto;
            bottom: -4vw;
            min-width: 420px;
            width: 40%;
            left: $inuit-global-spacing-unit-large;
        }

        .c-hero__scroll {
            position: absolute;
            bottom: $inuit-global-spacing-unit;
            left: 50%;
            z-index: 3;
            width: $inuit-global-spacing-unit-large;
            height: $inuit-global-spacing-unit-large;
            background: transparentize($colour-2, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 24px;
            transition: $global-transition;
            transform: translateX(-$inuit-global-spacing-unit);
            svg {
                transition: $global-transition;
                fill: white;
            }
            &:hover {
                background: transparentize(white, 0.1);
                svg {
                    fill: $colour-2;
                    transform: translateY($inuit-global-spacing-unit-tiny);
                }
            }
        }
    }

    @include media-query(xlarge) {
        figcaption {
            padding: $inuit-global-spacing-unit-large;
            &.c-hero__has-link {
                padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-large $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-large;
            }
            margin: 0 $inuit-global-spacing-unit-huge 0 0;
        }
        .svg-place {
            left: $inuit-global-spacing-unit-huge;
        }
    }

}

body:not(.home) {
    .c-hero {
        .c-hero__scroll {
            display: none;
        }
        .c-hero__image {
            padding-top: 50%;
        }
        @include media-query(medium-and-up) {
            .c-hero__image {
                padding-top: 50%;
            }
        }
        @include media-query(large) {
            height: auto;
            min-height: 520px;
            padding: $inuit-global-spacing-unit-large 0;
            .c-hero__image {
                padding-top: 0;
            }
        }
    }
}

