.c-floor-plans {
	background: lighten($colour-5, 24%);

	.c-floor-plans__inner {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		flex-wrap: wrap;
		//box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
	}

	h2 {
		max-width: $global-inner-limit;
		margin: 0 auto 0.25em auto;
	}

	.c-floor-plans__open {
		margin-bottom: $inuit-global-spacing-unit-small;
	}

	.c-floor-plans__close {
		display: flex;
		align-items: center;
		justify-content: center;
		background: $colour-1;

		svg {
			margin-left: 0.5em;
			pointer-events: none;
		}

		&:hover,
		&:focus {
			background: $colour-3;
		}
	}

	.c-floor-plans__stores {
		width: 100%;
		background: $colour-4;
		display: block;
		position: relative;
		order: 2;
		margin-top: $inuit-global-spacing-unit-small;

		h3 {
			background: $colour-4;
			height: 48px;
			margin: 0;
			padding: 0 $inuit-global-spacing-unit-small;
			display: flex;
			align-items: center;
			padding-top: 6px;

			&::before {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				width: 100%;
				height: 6px;
				background: $colour-2;
			}

			&::after {
				z-index: 2;
				content: '';
				position: absolute;
				width: 100%;
				top: $inuit-global-spacing-unit-large;
				left: 0;
				height: $inuit-global-spacing-unit-tiny;
				background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%);
			}
		}

		ul {
			height: 320px;
			overflow-y: scroll;
			margin: 0;
			position: relative;
			z-index: 1;

			li {
				&:first-of-type {
					margin-top: $inuit-global-spacing-unit;
				}

				&:last-of-type {
					margin-bottom: $inuit-global-spacing-unit;
				}

				button {
					padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
					text-decoration: none;
					display: block;
					background: rgba(255, 255, 255, 0.8);
					color: $colour-2;
					margin-bottom: 2px;
					border: 0;
					width: 100%;
					text-align: left;
					line-height: 1.5em;

					&:hover,
					&:focus,
					&.is-active {
						background: $colour-2;
						color: white;
					}

					&:focus {
						outline: none;
					}
				}
			}
		}

		&::after {
			z-index: 2;
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: $inuit-global-spacing-unit-large;
			background: linear-gradient(bottom, rgba(234, 233, 230, 1) 10%, rgba(234, 233, 230, 0) 100%);
		}
	}

	.c-floor-plans__buttons {
		button {
			margin-bottom: $inuit-global-spacing-unit-tiny;
		}
	}

	.c-floor-plans__tabs {
		width: 100%;
		display: none;
		flex-direction: column;
		order: 1;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		bottom: 0;

		&.is-visible {
			display: flex;
		}
	}

	.tab-controls-mobile {
		button {
			margin-bottom: $inuit-global-spacing-unit-tiny;
		}
	}

	.tab-controls {
		display: block;
		position: relative;
		order: 2;
		background: $colour-4;

		&::after {
			z-index: 2;
			content: '';
			position: absolute;
			width: 100%;
			left: 0;
			top: -$inuit-global-spacing-unit-tiny;
			height: $inuit-global-spacing-unit-tiny;
			background: linear-gradient(to top, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%);
		}

		.c-floor-plans__tab {
			flex: 1 1 0;
			width: 100%;
			height: 36px;
			border: none;
			margin: 0;
			background: $colour-4;
			color: $colour-2;
			border-right: 0;
			cursor: pointer;
			border-bottom: white 1px solid;
			position: relative;
			font-weight: 600;

			&::after,
			&::before {
				position: absolute;
				content: '';
				left: 0;
				width: 100%;
			}

			&::after {
				bottom: 0;
			}

			&:nth-of-type(1n) {
				&::after,
				&::before {
					background: $colour-1;
				}
			}

			&:nth-of-type(2n) {
				&::after,
				&::before {
					background: $colour-2;
				}
			}

			&:nth-of-type(3n) {
				&::after,
				&::before {
					background: $colour-3;
				}
			}

			&:nth-of-type(4n) {
				&::after,
				&::before {
					background: $colour-3;
				}
			}

			&:last-of-type {
				border-bottom: none;
			}

			&:hover,
			&:focus,
			&.is-active {
				background: white;

				span {
					position: relative;
					z-index: 2;
					pointer-events: none;
					color: white;
				}

				&::before {
					top: 0;
					height: 100%;
				}
			}

			&:focus {
				outline: none;
			}
		}
	}

	.tab-panels {
		position: relative;
		height: calc(100vh - 144px);
		order: 1;
	}

	.c-floor-plans__panel {
		background: white;
		position: absolute;
		padding: $inuit-global-spacing-unit;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: none;
		overflow: hidden;

		&.is-visible {
			display: block;
		}

		.c-floor-plans__plan {
			cursor: zoom-in;
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		svg {
			height: 100%;
			.is-highlighted {
				fill: #afca0b;
				fill-opacity: 100% !important;
				animation-name: flash;
				animation-duration: 3s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in-out;

				&#fp-collect polygon.ug-1,
				&#fp-collect path.ug-38 {
					fill: #afca0b;
					animation-name: flash;
					animation-duration: 3s;
					animation-iteration-count: infinite;
					animation-timing-function: ease-in-out;
				}
			}
			text {
				font-family: $global-font-family;
			}
		}
	}

	@include media-query(medium-and-up) {
		.c-floor-plans__inner {
			flex-direction: row;
		}

		.c-floor-plans__buttons {
			display: none;
		}

		.c-floor-plans__stores {
			width: 30%;
			order: 1;
			margin-top: 0;

			ul {
				height: 600px;
			}
		}

		.c-floor-plans__open {
			display: none;
		}

		.c-floor-plans__close {
			display: none;
		}

		.c-floor-plans__tabs {
			width: 70%;
			order: 2;
			position: relative;
			z-index: 1;
			display: flex;
		}

		.tab-controls {
			order: 1;
			flex-direction: row;
			display: flex;
			align-items: flex-end;

			.c-floor-plans__tab {
				flex: 0 0 auto;
				width: 25%;
				height: 48px;
				border-left: white 1px solid;
				border-bottom: none;
				padding-top: 6px;
				&:before {
					top: 0;
					height: 6px;
				}
				&:hover,
				&:focus,
				&.is-active {
					&:before {
						top: 0;
						height: 100%;
					}
					span {
						color: white;
					}
				}
			}

			&::after {
				top: 100%;
				background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%);
				//background: none;
			}
		}

		.tab-panels {
			order: 2;
			height: 600px;
		}
	}
}

@keyframes flash {
	0% {
		fill: darken(#afca0b, 8%);
	}
	50% {
		fill: #afca0b;
	}
	100% {
		fill: darken(#afca0b, 8%);
	}
}
