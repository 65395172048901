.c-social {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    li {
        svg {
            height: $inuit-global-font-size;
            transition: fill 0.3s ease;
        }

        a {
            line-height: 0;
            display: block;
        }
    }
}
