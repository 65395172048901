///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Colours.

$colour-1: #00aec3;
$colour-2: #141b4d;
$colour-3: #ee4d9b;
$colour-4: #eae9e6;
$colour-5: #28839a;
$colour-6: #6fbc84;
$colour-7: #c2a724;
$black: #151515;

$colour-valid: $colour-6;
$colour-invalid: $colour-3;

// Standardise some UI treatments.

$global-font-family: 'Red Hat Display', sans-serif;
$global-radius: 6px !default;
$global-transition: all 300ms ease-in-out !default;

$global-inner-limit: 1248px;

// Responsive Breakpoints.

$responsive: false; // Disabled for custom breakpoints/naming

// https://github.com/csswizardry/inuit.css/blob/v5.0.1/_defaults.scss#L100-L105
$medium-start: 701px;
$large-start: 1001px;
$xlarge-start: 1301px;

// Short variables for globals

$space-tiny: $inuit-global-spacing-unit-tiny;
$space-small: $inuit-global-spacing-unit-small;
$space: $inuit-global-spacing-unit;
$space-large: $inuit-global-spacing-unit-large;
$space-huge: $inuit-global-spacing-unit-huge
