.c-offer__info {
    background: $colour-4;
    padding-top: $space-large;
    padding-bottom: $space-large;
}

.c-offer__code {
    background: $colour-1;
    padding: $space-large 0;
    //margin-bottom: $space-large;
    label {
        color: $colour-2;
    }
    input {
        margin-bottom: $space-small;
        background: $colour-4;
    }
    button {
        margin-right: $space-small;
        &.is-copied {
            background-color: $colour-6;
        }
    }
}

.c-offer__terms {
    margin-top: $space-large;
}

.c-offer__terms + .c-offer__info--store {
    margin-top: $space-large;
}
