.c-times-title {
    color: $colour-2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg {
        margin-right: 0.5em;
        fill: $colour-2;
    }
    .c-times-title__main {
        display: block;
    }
    .c-times-title__sub {
        display: block;
        font-size: 1rem;
        color: $black;
    }
}

.c-times {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: $colour-2;

    dt {
        width: 8rem;
        margin: 0;
        border-bottom: 1px solid $colour-2;
        padding: 0.2em 0.2em 0;
        font-weight: bold;
        display: flex;
    }

    dd {
        width: calc(100% - 8rem);
        margin: 0;
        border-bottom: 1px solid $colour-2;
        padding: 0.2em 0.2em 0;
        display: flex;
        justify-content: flex-end;
    }

    .c-times__today {
        background: $colour-2;
        color: white;
    }
}
