/*------------------------------------*\
    $ALERT
\*------------------------------------*/

.c-flash-list {
    position: fixed;
    bottom: $inuit-global-spacing-unit;
    right: $inuit-global-spacing-unit;
    margin-bottom: 0;
    z-index: 2;
    width: calc(100% - 48px);
    max-width: 400px;
}

.c-flash {
    display: none;
    transition: opacity 0.3s ease;
    //opacity: 0;
    padding-top: $inuit-global-spacing-unit-small;

    &.is-active {
        display: block;
        //opacity: 1;
    }
}

.c-flash__body {
    position: relative;
    padding: $inuit-global-spacing-unit-small;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;

    > p {
        margin-bottom: 0;
        background: white;
        padding: $inuit-global-spacing-unit;
    }

    strong {
        display: block;
    }
}

.c-flash--valid, .alert-valid {
    .c-flash__body {
        background: $colour-valid;
    }
}

.c-flash--invalid, .alert-invalid {
    .c-flash__body {
        background: $colour-invalid;
    }
}

.c-flash__close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $inuit-global-spacing-unit-small;
    line-height: 1em;
    svg {
        height: $inuit-global-spacing-unit;
        width: auto;
        margin-left: 0.5em;
        fill: white;
        transition: fill 0.3s ease;
    }
    &:hover, &:focus {
        svg {
            fill: white;
        }
    }
}
