/* ==========================================================================
   #BOX
   ========================================================================== */

.o-section {

  padding: $space;

  @include media-query(medium-and-up) {
    padding: $space-large;
  }

  @include media-query(xlarge) {
    padding: $space-large $space-huge;
  }
}

.o-vertical-section {
  padding: $space 0;

  @include media-query(medium-and-up) {
    padding: $space-large 0;
  }

  @include media-query(xlarge) {
    padding: $space-huge 0;
  }
}
