.c-contact {
    padding: $inuit-global-spacing-unit 0;

    .c-contact__notice {
        span {
            color: $colour-3;
        }
    }

    form {
        li {
            margin-bottom: 1.5em;
        }
    }

    @include media-query(medium-and-up) {
        padding: $inuit-global-spacing-unit-large 0;
    }

    @include media-query(xlarge) {
        padding: $inuit-global-spacing-unit-huge 0;
    }
}
