.c-store-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: solid 1px white;
    padding: $space-small;

    figure {
        width: 100%;
        height: 0;
        padding-top: 50%;
        position: relative;
        background: white;
        margin: 0;
        img {
            position: absolute;
            top: 10%;
            left: 10%;
            width: 80%;
            height: 80%;
            object-fit: contain;
            font-family: 'object-fit: cover;';
        }
    }
    .c-store-card__info {
        margin: $space-small 0 0 0;
        h2 {
            margin-bottom: 0;
        }
    }

    @include media-query(medium-and-up) {
        flex-direction: row;
        figure {
            width: 50%;
            padding-top: 25%;
        }
        .c-store-card__info {
            margin: 0 $space;
        }
    }
}
