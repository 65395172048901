.c-header {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;

    &__search-toggle {
        display: flex;
        align-items: center;
        border: none;
        background: none;
        margin-left: auto;
        margin-right: 0.5rem;
        position: relative;
        cursor: pointer;

        svg {
            pointer-events: none;
            fill: $colour-2;
            .icon-search__close {
                transition: opacity 0.3s ease;
                opacity: 0;
            }
        }
        &.is-active {
            svg {
                .icon-search__close {
                    opacity: 1;
                }
            }
        }
        &:hover {
            svg {
                fill: $colour-1;
            }
        }
    }

    &__search {
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-left: auto;
        height: 3rem;
        padding: 0 2rem;
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(-0.5rem);
        transition: transform 0.3s ease;
        z-index: -1;
        gap: 1rem;
        box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.1);
        color: $colour-2;
        font-size: 0.9rem;

        &[aria-hidden="false"] {
            transform: translateY(3rem);
        }

        input {
            background: transparent;
            height: 1.5rem;
            border: none;
            border-bottom: 2px $colour-2 solid;
            &:focus {
                border-bottom: 2px $colour-1 solid;
                outline: none;
            }
        }

        button {
            display: flex;
            align-items: center;
            background: none;
            border: none;
            svg {
                fill: $colour-2;
                transition: transform 0.3s ease;
            }
            &:hover {
                svg {
                    transform: translateX(0.25rem);
                }
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: $inuit-global-spacing-unit-tiny;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .c-header__extra  {
        display: flex;
        position: absolute;
        background: $colour-4;
        top: 72px;
        right: 0;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
        transform: translateX(100%);
        transition: transform 0.3s ease;
        z-index: 1;

        > a {
            display: flex;
            font-size: 1em;
            line-height: 1em;
            margin-right: 1em;
            color: $colour-2;
            font-weight: 600;
            text-decoration: none;
            &:hover, &:focus {
                color: $colour-1;
            }
        }

        .c-social {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: auto;

            li {
                margin: 0 0 0 $inuit-global-spacing-unit-tiny;
            }

            svg {
                fill: $colour-2;
            }

            a {
                &:hover, &:focus {
                    svg {
                        fill: $colour-1;
                    }
                }
            }
        }

        &.is-active {
            transform: translateX(0);
        }
    }

    .c-header__main {
        background: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
        position: relative;
        z-index: 2;

        a {
            color: $colour-2;
        }

        nav {
            background: transparentize($colour-2, 0.025);
            position: absolute;
            top: 72px;
            left: 0;
            width: 100%;
            height: calc(100vh - 72px);
            padding: 72px $inuit-global-spacing-unit $inuit-global-spacing-unit $inuit-global-spacing-unit;
            transform: translateX(100%);
            transition: transform 0.3s ease;

            &.is-active {
                transform: translateX(0);
            }

            ul {
                margin-bottom: 0;
                text-align: right;

                li {
                    margin-left: $inuit-global-spacing-unit-small;
                    margin-bottom: 2vh;

                    a {
                        font-weight: 700;
                        font-size: 1.5rem;
                        color: white;
                        text-decoration: none;
                        position: relative;
                        transition: color 0.3s ease;

                        &:after {
                            content: '';
                            position: absolute;
                            width: 0;
                            height: 3px;
                            background: $colour-1;
                            bottom: -8px;
                            left: 0;
                            transition: $global-transition;
                        }

                        &:focus, &:hover, &.is-current {
                            &:after {
                                width: 100%;
                            }
                        }

                        &:focus, &:hover {
                            color: $colour-1;
                        }
                    }
                }
            }
        }
    }

    .c-header__logo {
        height: $inuit-global-spacing-unit-large;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        svg {
            width: 200px;
            height: auto;
        }

        .site-logo-a {
            transition: fill 0.3s ease;
            fill: $colour-1;
        }

        .site-logo-b {
            transition: fill 0.3s ease;
            fill: $colour-2;
        }

        &:hover {
            .site-logo-a {
                fill: $colour-3;
            }
        }
    }

    .c-header__toggle {
        width: 32px;
        height: 24px;
        border: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        background: transparent;

        span {
            display: block;
            width: 32px;
            height: 4px;
            background: $colour-2;
            transform-origin: center;
            transition: transform 0.3s ease;
        }

        &.is-active {
            span {
                &:nth-of-type(1) {
                    transform: rotate(45deg) translateY(7px) translateX(7px);
                }

                &:nth-of-type(2) {
                    opacity: 0;
                }

                &:nth-of-type(3) {
                    transform: rotate(-45deg) translateY(-7px) translateX(7px);
                }
            }
        }
    }

    @include media-query(medium) {

        &__search {
            border-bottom-left-radius: 12px;
            max-width: 40rem;
        }

    }

    @include media-query(large) {

        &__search-toggle {
            margin-left: 1rem;
            margin-right: 0;
            top: -0.25rem;
        }

        &__search {
            border-bottom-left-radius: 12px;
            max-width: 30rem;
        }

        .c-header__extra {
            display: flex;
            transform: translateX(0);
            width: auto;
            top: 0;
            border-bottom-left-radius: $inuit-global-spacing-unit-small;
            padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-large $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit;

            > a {
                font-size: 0.8em;
            }
        }

        .c-header__main {
            padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
            align-items: flex-end;

            nav {
                display: flex;
                background: transparent;
                position: relative;
                top: auto;
                left: auto;
                width: auto;
                height: auto;
                padding: 0;
                transform: translateX(0);
                margin-left: auto;

                ul {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 0.52rem; //Align with logo text

                    li {
                        margin-left: $inuit-global-font-size;
                        margin-bottom: 0;
                        display: flex;

                        a {
                            display: inline-block;
                            color: $colour-2;
                            font-size: 0.9rem;
                            line-height: 1rem;
                            text-align: left;
                            &:after {
                                background: $colour-2;
                            }
                        }
                    }
                }
            }
        }

        .c-header__toggle {
            display: none;
        }
    }

    @include media-query(xlarge) {
        .c-header__main {
            nav {
                ul {
                    margin-bottom: 0.54rem; //Align with logo text
                    li {
                        margin-left: $inuit-global-spacing-unit;
                        a {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }

        .c-header__logo {
            svg {
                width: 240px;
            }
        }
    }

    @media (prefers-reduced-motion) {
        .c-header__main {
            nav {
                transition: none;
            }
        }
        .c-header__extra {
            transition: none;
        }
    }
}

.home {

    .c-header__extra {
        background: white;
    }

    .c-header__main {
        background: $colour-1;

        .c-header__logo {
            .site-logo-a {
                fill: $colour-2;
            }

            .site-logo-b {
                fill: white;
            }

            &:hover {
                .site-logo-a {
                    fill: $colour-3;
                }
            }
        }

        nav {
            ul {
                li {
                    a {
                        &:hover, &:focus {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    .c-header__search-toggle {
        &:hover {
            svg {
                fill: white;
            }
        }
    }
}
